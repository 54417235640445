import React, { useState } from "react";

function ContactForm() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    // Optionally clear errors
    if (!!errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      alert("Form submitted successfully!");
    }
  };

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (!formData.fullName.trim()) {
      errors.fullName = "Full name is required";
      isValid = false;
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
      isValid = false;
    }

    if (!formData.message.trim()) {
      errors.message = "Message is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };
  const styles = {
    input: {
      marginBottom: "10px",
      padding: "12px 16px",
      fontSize: "16px",
      borderRadius: 8,
      outline: "none",
      backgroundColor: "#1E664330",
      borderWidth: 0,
      borderColor: "none", // Changed border color to white
      color: "#fff",
      fontFamily: "sans-serif",
    },
    button: {
      marginTop: "24px",
      padding: "12px 18px",
      fontSize: "18px",
      cursor: "pointer",
      background: "#07A658", // Horizontal gradient
      color: "#ffffff",
      border: "none",
      borderRadius: "8px",
      outline: "none",
      fontWeight: "bold",
    },
    error: {
      color: "red",
      marginBottom: "16px",
      fontSize: 13,
    },
  };

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "24px",
      }}
      onSubmit={handleSubmit}
    >
      <input
        style={styles.input}
        name="fullName"
        value={formData.fullName}
        onChange={handleChange}
        placeholder="Full name"
      />
      {errors.fullName && <div style={styles.error}>{errors.fullName}</div>}
      <input
        style={styles.input}
        name="email"
        type="email"
        value={formData.email}
        onChange={handleChange}
        placeholder="Email"
      />
      {errors.email && <div style={styles.error}>{errors.email}</div>}
      <textarea
        style={{ ...styles.input, minHeight: 140 }}
        name="message"
        value={formData.message}
        onChange={handleChange}
        placeholder="Type your message..."
      />
      {errors.message && <div style={styles.error}>{errors.message}</div>}
      <button style={styles.button} type="submit">
        Submit
      </button>
    </form>
  );
}

export default ContactForm;
